<template>
  <div class="hero top" style="background-image: url('/img/cp/alternative.jpg')">

  </div>
  <div class="container">
    <h2 class="mt-5 mb-5">{{$t("home.coverage_title")}}</h2>
  </div>
  <div class="container">
<!--    <h3 class="mt-4 mb-4">{{$t("home.what_is_covered")}}</h3>-->

    <div class="row d-flex justify-content-between">
      <div class="col-md-7" v-if="$i18n.locale === 'sk'" >
        <table class="table table-bordered covering">
          <tbody>
          <tr aria-rowindex="1">
            <td>
              liečebn&eacute; n&aacute;klady v pr&iacute;pade &uacute;razu a n&aacute;hleho ochorenia &ndash; vr&aacute;tane covid&nbsp;
            </td>
            <td>
              250 000 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="2">
            <td>
              <strong>n&aacute;klady na pobyt v karant&eacute;ne</strong>&nbsp;
            </td>
            <td>
              <strong>1 000 </strong><strong>&euro;</strong>&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="3">
            <td>
              ak&uacute;tne zubn&eacute; o&scaron;etrenie&nbsp;
            </td>
            <td>
              600 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="4">
            <td>
              asistenčn&eacute; služby v zahranič&iacute;&nbsp;
            </td>
            <td>
              80 000 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="5">
            <td>
              trval&eacute; n&aacute;sledky &uacute;razu / smrť &uacute;razom&nbsp;<br>
              <div class="">- deti do 18 rokov</div>
              <div class="">- manuálna  práca</div>

            </td>
            <td>
              <span style="white-space: nowrap">100 000 &euro;&nbsp;</span><br>
              5 000 &euro;&nbsp;<br>
              10 000 &euro;&nbsp;<br>
            </td>
          </tr>
          <tr aria-rowindex="6">
            <td>
              cestovn&eacute; n&aacute;klady n&aacute;hradn&iacute;ka na služobnej ceste&nbsp;
            </td>
            <td>
              1 500 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="7">
            <td>
              cestovn&eacute; v&yacute;davky pre rodinn&yacute;ch pr&iacute;slu&scaron;n&iacute;kov poisten&eacute;ho&nbsp;
            </td>
            <td>
              1 500 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="8">
            <td>
              batožina &ndash; kr&aacute;dež, strata, po&scaron;kodenie&nbsp;
            </td>
            <td>
              1 500 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="9">
            <td>
              pr&aacute;vna pomoc a kaucia&nbsp;
            </td>
            <td>
              5 000 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="10">
            <td>
              oneskoren&eacute; dodanie batožiny pri ceste lietadlom, me&scaron;kanie letu&nbsp;
            </td>
            <td>
              800 &euro;&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="11">
            <td>
              <strong>n&aacute;klady p&aacute;trania a horskej služby &ndash; na Slovensku aj v zahranič&iacute;&nbsp;</strong>&nbsp;
            </td>
            <td>
              <strong>16 000 </strong><strong>&euro;</strong>&nbsp;
            </td>
          </tr>
          <tr aria-rowindex="12">
            <td>
              zodpovednosť za &scaron;kody na veciach a na zdrav&iacute; tret&iacute;ch os&ocirc;b&nbsp;
            </td>
            <td>
              450 000 &euro;&nbsp;
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="col-md-7">
        <table class="table table-bordered covering">
          <tbody>
          <tr aria-rowindex="1">
            <td>
              léčebné výlohy pro případ úrazu a nečekané nemoci – včetně Covid 19
            </td>
            <td>
              6 250 000 Kč
            </td>
          </tr>
          <tr aria-rowindex="2">
            <td>
              <strong>náklady na pobyt v karanténě</strong>&nbsp;
            </td>
            <td>
              <strong>25 000 Kč</strong>
            </td>
          </tr>
          <tr aria-rowindex="3">
            <td>
              akutní zubní ošetření
            </td>
            <td>
              15 000 Kč
            </td>
          </tr>
          <tr aria-rowindex="4">
            <td>
              asistenční služby v zahraničí
            </td>
            <td>
              2 000 000 Kč
            </td>
          </tr>
          <tr aria-rowindex="5">
            <td>
              trvalé následky úrazu / smrt následkem úrazu&nbsp;<br>
              <div class="">- děti do 18 let</div>
              <div class="">- manuální  práce</div>

            </td>
            <td>
              <span style="white-space: nowrap">2 500 000 Kč</span><br>
              125 000 Kč<br>
              250 000 Kč <br>
            </td>
          </tr>
          <tr aria-rowindex="6">
            <td>
              cestovní výdaje náhradníka na služební cestě
            </td>
            <td>
              37 500 Kč
            </td>
          </tr>
          <tr aria-rowindex="7">
            <td>
              cestovní výdaje rodinného příslušníka pojištěného
            </td>
            <td>
              37 500 Kč
            </td>
          </tr>
          <tr aria-rowindex="8">
            <td>
              zavazadla – krádež, ztráta, poškození
            </td>
            <td>
              37 500 Kč
            </td>
          </tr>
          <tr aria-rowindex="9">
            <td>
              právní pomoc a kauce
            </td>
            <td>
              125 000 Kč
            </td>
          </tr>
          <tr aria-rowindex="10">
            <td>
              zdržení zavazadla pří použití letecké dopravy, zdržení letu
            </td>
            <td>
              20 000 Kč
            </td>
          </tr>
          <tr aria-rowindex="11">
            <td>
              <strong>náklady pátrání a horské služby – v Čechách i v zahraničí &nbsp;</strong>&nbsp;
            </td>
            <td>
              <strong>400 000 Kč</strong>
            </td>
          </tr>
          <tr aria-rowindex="12">
            <td>
              odpovědnost za škody na majetku a na zdraví třetích osob
            </td>
            <td>
              11 250 000 Kč
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-5 text-center d-flex flex-md-row-row flex-md-column-reverse align-items-center  justify-content-around">
        <div class="price-wrap d-flex flex-column justify-content-center">
          <div class="price">
            <div class="price-title">
              {{$t('home.price_title')}}
            </div>
            {{$t('home.price')}}
          </div>
        </div>
        <router-link to="/order" class="btn btn-primary shadow-lg btn-lg">{{$t('home.cta')}}</router-link>
      </div>
    </div>


  </div>
  <div class="container">
    <h3 class="mt-5">{{$t('home.additional_services.title')}}</h3>
    <div class="row mb-5 mt-4 justify-content-around additional-services">
      <div class="">
        <router-link to="/additional" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
                <img src="/img/cp/passenger-with-baggage.png">
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">-->
<!--                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>-->
<!--                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>-->
<!--              </svg>-->
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.companions.title')"></h3>
            </div>
          </div>
        </router-link>
      </div>
      <div class="">
        <a :href="tripExtensionURL" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/beach-umbrella.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.trip_extension.title')"></h3>
            </div>
          </div>
        </a>
      </div>
      <div class="">
        <a :href="tripCancellationURL" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/suitcase.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.trip_cancel.title')"></h3>
            </div>
          </div>
        </a>
      </div>
      <div v-if="carTripURL">
        <a :href="carTripURL" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/ground-transportation.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.car.title')"></h3>
            </div>
          </div>
        </a>
      </div>
      <div v-if="bikeTripURL">
        <a :href="bikeTripURL" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/bicycle.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.bike.title')"></h3>
            </div>
          </div>
        </a>
      </div>
      <div  v-if="isSlovak">
        <a href="#" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/beach%20blue.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.short_term')"></h3>
            </div>
          </div>
        </a>
      </div>

      <div v-if="isSlovak">
        <a  href="#" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/national-park.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.park')"></h3>
            </div>
          </div>
        </a>
      </div>
      <div v-if="isSlovak">
        <a  target="_blank" :href="$t('home.additional_services.golf_url')" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/golf.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.golf')"></h3>
            </div>
          </div>
        </a>
      </div>
      <div v-if="isSlovak">
        <a href="#" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/sail-boat.png">
            </div>
            <div class="card-body">
              <h3 class="card-title" v-html="$t('home.additional_services.ship')"></h3>
            </div>
          </div>
        </a>
      </div>
      <div>
        <a href="/drone" class="text-decoration-none" style="color: rgb(44, 62, 80);">
          <div class="card text-center">
            <div class="card-img-top text-center">
              <img src="/img/cp/drone.png">
            </div>
            <div class="card-body">
              <h3 class="card-title">poistenie<br>dronov</h3>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="container">
    <h3 class="mt-5">{{$t("home.faq_title")}}</h3>


    <div class="mb-5 mt-4">
      <FAQPart />
    </div>
  </div>


  <!--  <div class="home">-->
  <!--    <img alt="Vue logo" src="../assets/logo.png">-->
  <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <!--  </div>-->

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
//import HeaderPart from "@/components/parts/HeaderPart";
import FAQPart from "@/components/accordion/FAQPart";

export default {
  name: 'HomeView',
  components: {
    FAQPart
  },
  data: function (){
    return {
      tripExtensionURL: '/trip-extension',
      tripCancellationURL: '#',
      carTripURL: '',
      bikeTripURL: ''
    }
  },
  computed: {
    isSlovak: function (){
      return this.$i18n.locale === 'sk';
    }
  },
  mounted() {
    if(this.$i18n.locale === 'sk') {
      this.tripExtensionURL = '/trip-extension';
      this.tripCancellationURL = '/trip-cancellation';
      this.carTripURL = '/car';
      this.bikeTripURL = '/bike';
    }
  }
}
</script>
<style>
img{
  max-width: 100%;

}
strong{
  color: var(--main-color);
}


.hero.has-content:after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  opacity: 1;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.0) 70%);
}
.hero .container{
  position: relative;
  z-index: 2;
}
.hero h2{
  font-size: 2.5rem;
  margin-top: 320px;
  padding-bottom: 15px;
  color: white;
}

.hero.top{
  background-position: right 10% bottom 0%;
}

.hero.bottom{
  background-position: left bottom 60%;
}
.covering{
  font-size: 0.9em;

}
.covering td{
  padding: 5px 8px;
}
.table td:nth-child(2){
  text-align: right;
}
.additional-services{
  align-items: stretch;

}
.additional-services > div{
  margin-top: 20px;
  width: 20%;
  flex: 1;
  max-width: 300px;
  min-width: 250px;
}

.additional-services h3{
  color: rgb(0, 0, 0, .7);
  font-size: 1.4em;
}

.additional-services .card{
  flex: 1;
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.additional-services .card-img-top{
  margin-top: 20px;
}

.additional-services .card-img-top img{
  max-width: 80px;
}

.additional-services .card-body{
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.additional-services .card-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.price-wrap{
  flex: 1;
}

.price{
  text-align: center;
  font-size: 3em;
  color: var(--main-color);
  font-weight: bold;
}

.price-title{
  font-weight: normal;
  font-size: 0.5em;
  margin-bottom: -10px;
}
</style>
