<template>
  <div class="accordion" id="parent">
      <ItemPart :content="content" :first="index === 0" :title="title" parent-id="parent" v-for="({ title, content}, index) in data" :key="title"></ItemPart>
  </div>

</template>
<script>

//import ItemPart from "@/components/accordion/ItemPart";
import ItemPart from "@/components/accordion/ItemPart";
import axios from "axios";
import common from "@/config/common";
export default {
  components: {ItemPart},
  computed: {
    code(){
      return this.$product + '_' + this.$i18n.locale;
    }
  },
  data: function (){
      return {
        data: {}
      }
  },
  created() {
    var self = this;
    axios.get(common.API_BASE_URL + this.$product +'/'+ this.$i18n.locale +'/questions') // 'getCityByPostcode/' + this.formData.postcode
        .then(response => {
          self.data = response.data;
        })
        .catch(error => {
          console.log(error);
          //alert('fetchError' + error);
        })
  },
}
</script>
<style>
.accordion-button:not(.collapsed){
  color: var(--main-color);
  background-color: var(--main-color-light);
}
.accordion-button:not(.collapsed)::after{
  filter: hue-rotate(249deg);
}
</style>