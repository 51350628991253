import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import {i18n} from './i18n'
import router from './router'
// Register it globally
//import { ValidationProvider} from 'vee-validate';
var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sk.json"));
countries.registerLocale(require("i18n-iso-countries/langs/cs.json"));



var app = createApp(App).use(router);
app.config.globalProperties.$product = 'cp'
//app.use(vue3_accessible_accordions);
//import CookieConsent from 'vue-cookieconsent'

//import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css'
//import './theme/cookieconsent_custom.css' // custom cookie consent styles
//app.component('ValidationProvider', ValidationProvider);
//app.use(CookieConsent, consentOptions)
app.use(i18n).mount('#app')
